<template>
  <div id="real">
    <Three1 v-bind:real="real" />
  </div>
</template>
<script>
import * as util from "@/unit/network";
import Three1 from "../attend/Three1.vue";
export default {
  data() {
    return {
      real: "",
    };
  },
  mounted() {
    this.getinfo();
  },
  methods: {
    getinfo() {
      util.post("/catalogFileInfo", { id: this.$route.query.dataid }, (res) => {
        console.log(res);
        this.real = res.data.file_path;
      });
    },
  },
  components: {
    Three1,
  },
};
</script>